import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {
    mounted: false,
    'controller-token-': '',
    'requestray-token-': '',
    'firewall-token-': '',
    'cache-token-': '',
    'trans-token-': ''
  },
  mutations: {
    UPDATE_TOKEN(state, payload) {
      state['controller-token-'] = payload['controller-token-'],
        state['requestray-token-'] = payload['requestray-token-'],
        state['firewall-token-'] = payload['firewall-token-'],
        state['cache-token-'] = payload['cache-token-'],
        state['trans-token-'] = payload['trans-token-']
    },
    UPDATE_MOUNTED(state, payload) {
      state.mounted = payload
    }

  },
})