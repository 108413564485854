((g) => {

  g.pixel = {
    base_url: process.env.VUE_APP_API_HOST
  };

  g.pixel.uuidv4 = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };

  g.pixel.handleIframe = function (product_id, pixel_id, host, event, event_id, affiliate_id, amount, method, sale_id, email, ip_address, user_agent, fbp, firstName, lastName, fbc, cellphone, city, state, zipcode, external_id) {
    let iframe = document.createElement('iframe');

    let url = `https://${host}/${product_id}?event=${event}&event_id=${event_id}&pixel_id=${pixel_id}${method}&amount=${amount}&a_id=${affiliate_id}&s_id=${sale_id}&client_ip_address=${encodeURIComponent(ip_address)}&client_user_agent=${encodeURIComponent(user_agent)}&fbp=${fbp}&fbc=${fbc}&external_id=${external_id}`;

    if (event === 'conversion') {
      url += `&em=${email}&fn=${firstName}&ln=${lastName}&ph=${cellphone}&ct=${city}&st=${state}&zp=${zipcode}`;
    }

    iframe.src = url;
    iframe.width = 1;
    iframe.height = 1;
    iframe.style.display = 'none';
    document.querySelector('body').appendChild(iframe);
  };

  g.pixel.getPixels = function (event, product_id, event_id, method, affiliate_id, amount, sale_id, email, ip_address, fbp, firstName, lastName, fbc, cellphone, city, state, zipcode, external_id) {
    let url = `${g.pixel.base_url}/api/lexip/?product_id=${product_id}&event=${event}&event_id=${event_id}${method}&amount=${amount}&a_id=${affiliate_id}&s_id=${sale_id}&ip=${encodeURIComponent(ip_address)}&fbp=${fbp}&fbc=${fbc}&external_id=${external_id}`;

    if (event === 'conversion') {
      url += `&email=${encodeURIComponent(email)}&first_name=${encodeURIComponent(firstName)}&last_name=${encodeURIComponent(lastName)}&cellphone=${encodeURIComponent(cellphone)}&city=${encodeURIComponent(city)}&state=${encodeURIComponent(state)}&zipcode=${encodeURIComponent(zipcode)}`
    }

    return new Promise((resolve, reject) => {
      fetch(url)
        .then(res => res.json())
        .then(data => {
          resolve(data.pixels);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        })
    });
  };

  g.pixel.handleEvent = async (event, product_id, affiliate_id, method, amount, sale_id = undefined, email = '', ip_address = '', user_agent = '', fbp = '', name = '', fbc = '', cellphone = '', city = '', state = '', zipcode = '', external_id = '') => {
    if (!affiliate_id) {
      affiliate_id = '';
    }
    if (!method) {
      method = '';
    } else {
      method = '&method=' + method;
    }
    let event_id = g.pixel.uuidv4();

    let fullName = name.split(" ");

    let firstName = fullName.shift();
    let lastName = fullName.join(" ");

    const hashedFirstName = firstName ? await g.pixel.hashField(firstName) : '';
    const hashedLastName = lastName ? await g.pixel.hashField(lastName) : '';
    const hashedEmail = email ? await g.pixel.hashField(email) : '';
    const hashedCity = city ? await g.pixel.hashField(city) : '';
    const hashedState = state ? await g.pixel.hashField(state) : '';
    const hashedZipCode = zipcode ? await g.pixel.hashField(zipcode.replace(/-/g, '')) : '';
    const hashedExternalId = external_id ? await g.pixel.hashField(external_id.toString()) : '';
    const hashedPhone = cellphone ? await g.pixel.hashField(cellphone.replace(/^\+/, '')) : '';

    let pixels = await g.pixel.getPixels(event, product_id, event_id, method, affiliate_id, amount, sale_id, email, ip_address, fbp, firstName, lastName, fbc, cellphone, city, state, zipcode, external_id);
    
    if (pixels && pixels.length) {
      for await (const pixel of pixels) {
        g.pixel.handleIframe(product_id, pixel.id, pixel.host, event, event_id, affiliate_id, amount, method, sale_id, hashedEmail, ip_address, user_agent, fbp, hashedFirstName, hashedLastName, fbc, hashedPhone, hashedCity, hashedState, hashedZipCode, hashedExternalId);
      }
    }
  };

  g.pixel.hashField = async (field, lowercase = true) => {
    let formattedMessage = field;

    if (lowercase) {
      formattedMessage = formattedMessage.trim().toLowerCase();
    }

    const encoder = new TextEncoder();
    const data = encoder.encode(formattedMessage);

    const hashBuffer = await crypto.subtle.digest('SHA-256', data);

    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const finalHash = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

    return finalHash;
  }

})(window);
